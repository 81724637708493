/*eslint-disable*/ export const messages = {
  'components.image.dialog.description.label': 'Image description',
  'components.image.dialog.description.placeholder': 'Add a short description here.',
  'components.image.dialog.label': 'Image',
  'components.image.dialog.source.label': 'Image source',
  'components.image.menu.icon.label': 'Image',
  'components.italic.menu.icon.label': 'Italic',
  'components.underline.menu.icon.label': 'Underline',
  'extension.command.add-annotation.label': 'Add annotation',
  'extension.command.center-align.label': 'Center align',
  'extension.command.convert-paragraph.description':
    'Convert current block into a paragraph block.',
  'extension.command.convert-paragraph.label': 'Convert Paragraph',
  'extension.command.copy.description': 'Copy the selected text',
  'extension.command.copy.label': 'Copy',
  'extension.command.create-table.description':
    'Create a table with set number of rows and columns.',
  'extension.command.create-table.label': 'Create table',
  'extension.command.cut.description': 'Cut the selected text',
  'extension.command.cut.label': 'Cut',
  'extension.command.decrease-font-size.description': 'Decrease the font size.',
  'extension.command.decrease-font-size.label': 'Decrease',
  'extension.command.decrease-indent.label': 'Decrease indentation',
  'extension.command.increase-font-size.description': 'Increase the font size',
  'extension.command.increase-font-size.label': 'Increase',
  'extension.command.increase-indent.label': 'Increase indentation',
  'extension.command.insert-emoji.label': 'Insert Emoji',
  'extension.command.insert-horizontal-rule.description':
    'Separate content with a diving horizontal line',
  'extension.command.insert-horizontal-rule.label': 'Divider',
  'extension.command.insert-paragraph.description': 'Insert a new paragraph',
  'extension.command.insert-paragraph.label': 'Insert Paragraph',
  'extension.command.justify-align.label': 'Justify',
  'extension.command.left-align.label': 'Left align',
  'extension.command.paste.description': 'Paste content into the editor',
  'extension.command.paste.label': 'Paste',
  'extension.command.redo.description': 'Redo the most recent action',
  'extension.command.redo.label': 'Redo',
  'extension.command.remove-annotation.label': 'Remove annotation',
  'extension.command.right-align.label': 'Right align',
  'extension.command.select-all.description': 'Select all content within the editor',
  'extension.command.select-all.label': 'Select all',
  'extension.command.set-casing.label': 'Set text case',
  'extension.command.set-font-size.description': 'Set the font size for the selected text.',
  'extension.command.set-font-size.label': 'Font size',
  'extension.command.set-text-color.description': 'Set the text color for the selected text.',
  'extension.command.set-text-color.label': 'Text color',
  'extension.command.set-text-direction.description': [
    [
      'dir',
      'select',
      {
        ltr: 'Set the text direction from left to right',
        rtl: 'Set the text direction from right to left',
        other: 'Reset text direction',
      },
    ],
  ],
  'extension.command.set-text-direction.label': [
    ['dir', 'select', { ltr: 'Left-To-Right', rtl: 'Right-To-Left', other: 'Reset Direction' }],
  ],
  'extension.command.set-text-highlight.description':
    'Set the text highlight color for the selected text.',
  'extension.command.set-text-highlight.label': 'Text highlight',
  'extension.command.toggle-blockquote.description':
    'Add blockquote formatting to the selected text',
  'extension.command.toggle-blockquote.label': 'Blockquote',
  'extension.command.toggle-bold.description': 'Add bold formatting to the selected text',
  'extension.command.toggle-bold.label': 'Bold',
  'extension.command.toggle-bullet-list.description': 'Bulleted list',
  'extension.command.toggle-callout.description': [
    [
      'type',
      'select',
      {
        info: 'Create an information callout block',
        warning: 'Create a warning callout block',
        error: 'Create an error callout block',
        success: 'Create a success callout block',
        other: 'Create a callout block',
      },
    ],
  ],
  'extension.command.toggle-callout.label': [
    [
      'type',
      'select',
      {
        info: 'Information Callout',
        warning: 'Warning Callout',
        error: 'Error Callout',
        success: 'Success Callout',
        other: 'Callout',
      },
    ],
  ],
  'extension.command.toggle-code-block.description': 'Add a code block',
  'extension.command.toggle-code-block.label': 'Codeblock',
  'extension.command.toggle-code.description': 'Add inline code formatting to the selected text',
  'extension.command.toggle-code.label': 'Code',
  'extension.command.toggle-columns.description': [
    [
      'count',
      'select',
      {
        2: 'Split the block into two columns',
        3: 'Split the current block into three columns',
        4: 'Split the current block into four columns',
        other: 'Split the current block into multiple columns',
      },
    ],
  ],
  'extension.command.toggle-columns.label': [
    [
      'count',
      'select',
      {
        2: 'Two Column Block',
        3: 'Three Column Block',
        4: 'Four Column Block',
        other: 'Multi Column Block',
      },
    ],
  ],
  'extension.command.toggle-heading.label': [
    [
      'level',
      'select',
      {
        1: 'Heading 1',
        2: 'Heading 2',
        3: 'Heading 3',
        4: 'Heading 4',
        5: 'Heading 5',
        6: 'Heading 6',
        other: 'Heading',
      },
    ],
  ],
  'extension.command.toggle-italic.description': 'Italicize the selected text',
  'extension.command.toggle-italic.label': 'Italic',
  'extension.command.toggle-ordered-list.label': 'Ordered list',
  'extension.command.toggle-strike.description': 'Strikethrough the selected text',
  'extension.command.toggle-strike.label': 'Strikethrough',
  'extension.command.toggle-subscript.label': 'Subscript',
  'extension.command.toggle-superscript.label': 'Superscript',
  'extension.command.toggle-task-list.description': 'Tasked list',
  'extension.command.toggle-underline.description': 'Underline the selected text',
  'extension.command.toggle-underline.label': 'Underline',
  'extension.command.toggle-upper-case.label': [
    [
      'case',
      'select',
      {
        upper: 'Uppercase',
        lower: 'Lowercase',
        capitalize: 'Sentence case',
        smallCaps: 'Small caps',
        other: 'Text case',
      },
    ],
  ],
  'extension.command.toggle-whitespace.description':
    'Show hidden whitespace characters in your editor.',
  'extension.command.toggle-whitespace.label': 'Toggle Whitespace',
  'extension.command.undo.description': 'Undo the most recent action',
  'extension.command.undo.label': 'Undo',
  'extension.command.update-annotation.label': 'Update annotation',
  'extension.table.column_count': [
    ['count', 'plural', { one: ['#', ' column'], other: ['#', ' columns'] }],
  ],
  'extension.table.row_count': [['count', 'plural', { one: ['#', ' row'], other: ['#', ' rows'] }]],
  'keyboard.shortcut.alt': 'Alt',
  'keyboard.shortcut.arrowDown': 'Arrow Down',
  'keyboard.shortcut.arrowLeft': 'Arrow Left',
  'keyboard.shortcut.arrowRight': 'Arrow Right',
  'keyboard.shortcut.arrowUp': 'Arrow Up',
  'keyboard.shortcut.backspace': 'Backspace',
  'keyboard.shortcut.capsLock': 'Caps Lock',
  'keyboard.shortcut.command': 'Command',
  'keyboard.shortcut.control': 'Control',
  'keyboard.shortcut.delete': 'Delete',
  'keyboard.shortcut.end': 'End',
  'keyboard.shortcut.enter': 'Enter',
  'keyboard.shortcut.escape': 'Enter',
  'keyboard.shortcut.home': 'Home',
  'keyboard.shortcut.pageDown': 'Page Down',
  'keyboard.shortcut.pageUp': 'Page Up',
  'keyboard.shortcut.shift': 'Shift',
  'keyboard.shortcut.space': 'Space',
  'keyboard.shortcut.tab': 'Tab',
  'react-components.mention-atom-component.zero-items': 'No items available',
  'react-components.top-menu.label': 'Static Menu',
  'ui.text-color.black': 'Black',
  'ui.text-color.blue': 'Blue',
  'ui.text-color.blue.hue': ['Blue ', ['hue']],
  'ui.text-color.cyan': 'Cyan',
  'ui.text-color.cyan.hue': ['Cyan ', ['hue']],
  'ui.text-color.grape': 'Grape',
  'ui.text-color.grape.hue': ['Grape ', ['hue']],
  'ui.text-color.gray': 'Gray',
  'ui.text-color.gray.hue': ['Gray ', ['hue']],
  'ui.text-color.green': 'Green',
  'ui.text-color.green.hue': ['Green ', ['hue']],
  'ui.text-color.indigo': 'Indigo',
  'ui.text-color.indigo.hue': ['Indigo ', ['hue']],
  'ui.text-color.lime': 'Lime',
  'ui.text-color.lime.hue': ['Lime ', ['hue']],
  'ui.text-color.orange': 'Orange',
  'ui.text-color.orange.hue': ['Orange ', ['hue']],
  'ui.text-color.pink': 'Pink',
  'ui.text-color.pink.hue': ['Pink ', ['hue']],
  'ui.text-color.red': 'Red',
  'ui.text-color.red.hue': ['Red ', ['hue']],
  'ui.text-color.teal': 'Teal',
  'ui.text-color.teal.hue': ['Teal ', ['hue']],
  'ui.text-color.transparent': 'Transparent',
  'ui.text-color.violet': 'Violet',
  'ui.text-color.violet.hue': ['Violet ', ['hue']],
  'ui.text-color.white': 'White',
  'ui.text-color.yellow': 'Yellow',
  'ui.text-color.yellow.hue': ['Yellow ', ['hue']],
};
